import React from "react";
import theme from "theme";
import { Theme, Box, Em, Text, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"spie2025"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px">
			<Override slot="linkBox7" color="white" />
			<Override slot="text17" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box min-width="100px" min-height="100px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="36px"
				padding="50px 16px 100px 16px"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="flex-start"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--headline3"
					>
						Press release: SPhotonix and Photonics West{" \n\n"}
						<br />
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Date: 20th January 2025{"\n\n\n\n\n\n"}
						</Em>
					</Text>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--bodyText17SemiBold"
					/>
					<Text margin="0px 0px 0px 0px" text-align="justify" font="--bodyText21Regular">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							SPhotonix to debut at global-leading technology exhibition{" "}
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Photonics West{" "}
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							28th-30th January 2025, The Moscone Center, San Francisco,{" "}
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Booth number 2#638
						</Em>
						{"\n\n"}
						<br />
						<br />
						•       First-ever appearance from the pioneering photonics firm with its two
ground-breaking products – DIC Prisms and Polarization Smoothers{"  \n\n"}
						<br />
						<br />
						•       Delegates can participate in a live demo of the DIC Prisms and UV Retarders for large 
laser which are set to revolutionise the optical devices market{" \n\n"}
						<br />
						<br />
						•       Spearheaded by Prof. Peter Kazansky based on decades of research
conducted at Southampton University, England which pioneered the human genome
data storage{"\n\n"}
						<br />
						<br />
						Newark, Delaware, 20 January 2025 :{" "}
						<Link
							href="https://sphotonix.com"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							SPhotonix
						</Link>
						{" "}has announced it will be exhibiting for the first time at this month’s Photonics West debuting it’s world first optical device fabrication technology. The annual event gathers industry leaders and experts to explore cutting-edge
advancements in optical technologies, fostering collaboration and setting
future trends. For the three-day event, visitors can experience SPhotonix’s predictions on the future of optical processes.{" \n\n"}
						<br />
						<br />
						SPhotonix will present, for the first-time ever, its two latest
innovations, designed for both current and prospective clients, DIC Prisms and
Beam Smoothers.  These products will
address specific needs within various sectors, appealing not only to new
customers but also to researchers and developers engaged in cutting-edge
projects. SPhotonix is the only brand currently able to provide both products.{"\n\n"}
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							DIC
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}Prisms
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						Manufactured for microscopy companies, the high-quality optical DIC
Prisms offer superior performance, enhanced durability, and cost-effective
technical features compared to competing products. These prisms are designed to
improve image clarity and precision, making them essential tools for advanced
scientific research.{"\n\n"}
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Beam Smoother{" "}
						</Strong>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						The Beam Smoother offers a groundbreaking approach to manipulation of
high power laser beams. This innovative technology is one of SPhotonix's unique
selling propositions, showcasing revolutionizing approach to the harnessing
light in high power applications.{"\n\n"}
						<br />
						<br />
						Ilya Kazansky, CEO of SPhotonix said: “Optical technology is entering a critical phase, driven by increasing
demand for advanced component fabrication. As the industry's sole manufacturer
of these essential products, we are ready to address researchers’ needs in other areas ranging from space technology to life
science.  Our DIC Prisms revolutionise
imaging , while the Beam Smoothers dramatically cut costs in large laser
energetics. These are truly game-changing innovations.”{"\n\n"}
						<br />
						<br />
						This development follows hot on the heels of SPhotonix launch of its 5D
Memory Crystal nano etching technology that can store upto 360TB data on a 5
inch quartz crystal. SPhotonix is spin out from{" "}
						<Link
							href="https://www.southampton.ac.uk/news/2024/09/human-genome-stored-on-everlasting-memory-crystal-.page"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							University of
Southampton’s Optoelectronics Research Centre{" "}
						</Link>
						that recently stored the human genome on a memory crystal that can last
billions of years. The revolutionary technology developed for that purpose is
now being applied to create these groundbreaking optical elements.

The Photonics West 2025 program boasts an impressive lineup of
distinguished plenary speakers, fostering cutting-edge insights in photonics. Participants can engage in numerous technical and networking events,
explore over 50 diverse course options, tour four dynamic exhibitions, and
immerse themselves in a comprehensive industry program designed to enhance
collaboration and innovation.{"\n\n\n\n"}
					</Text>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Components.Footer />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});